
import React from "react";
import stg1 from "./images/mehfil-jharkhand-stage-decoration1.jpeg";
import stg2 from "./images/mehfil-hazaribag-stage-decoration.jpeg";
import stg3 from "./images/mehfil-jharkhand-stage-decoration.jpeg";
import stg4 from "./images/mehfil-sindur-stage-decoration.jpeg";
const images = [
    {
      imgURL: stg1,
      imgAlt: "best-party-hall-hazaribag-stage-decoration"
    },
    {
      imgURL:stg2,
      imgAlt: "mehfil-banquet-hazaribag-stage-decoration"
    },
    {
      imgURL:stg3,
      imgAlt: "affordable-banquet-stage-decoration"
    },
    {
      imgURL:stg4,
      imgAlt: "awesome-stage"
    }
  ];
  
  export default images;
  