import React from "react";
import PageTitle from "./PageTitle";

const ContactUs = () => {
    return <>
    <br/><br/>
     <PageTitle heading="Contact Us" id="contactus-mehfil-banquet-in-hazaribagh"  subheading=""/>
      	<div className="mainsection">
             <div className="primarysection">
             <p class="text-small"> 
             <strong>Phone</strong><br/>
             8527131889
             <br/><br/>

             <strong>Email</strong><br/>
             surendramehta35@yahoo.co.in 
             <br/><br/>

             <strong>Address</strong><br/>
					Bali Complex, <br/> 
            Near Royal Enfield Showroom, <br/> 
            V.B.U<br/> 
            Sindoor, NH-33, <br/>
            Hazaribag <br/>
            Jharkhand-825301<br/><br/>
				</p>
       
             </div>
             <div className="secondarysection">
             <iframe className="iframe" width="100%" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3644.1359735810656!2d85.3762850755725!3d24.026269778483247!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f49dbb8b09501b%3A0x4ab356742bc1c29a!2sMEHFIL%20BANQUET!5e0!3m2!1sen!2sin!4v1729435339087!5m2!1sen!2sin" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>


  </div>
    </>
}

export default ContactUs;